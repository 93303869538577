<template>
  <v-footer tile height="80" class="font-weight-medium shadow-sm ma-4 rounded-lg noprint elevation-0" :color="$vuetify.theme.dark ? 'dark' : 'white'">
    <v-col class="text-left" cols="12">
      <v-row class="d-flex align-center">
        <v-col cols="9" class="py-0">
          <v-row no-gutters class="align-center">
            <v-btn target="_blank" depressed color="primary lighten-2" outlined @click="goToContact">
              Contact us
            </v-btn>
            <strong class="text--disabled ml-2 d-none d-sm-flex">
              Need help? Looking for more details?
            </strong>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-end py-0">
          <strong class="text--disabled">
            © Vumetric {{ new Date().getFullYear() }}
          </strong>
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'

export default {
  methods: {
    goToContact() {
      if (this.$route.path === '/contact-us') {
        snackbarPlugin.showError('You are already on the contact page')
      } else {
        this.$router.push('/contact-us')
      }
    }
  }
}
</script>

<style>
@media print {
  .noprint {
    display: none !important;
  }
}
</style>
